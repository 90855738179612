import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children: 'https://i.imgur.com/mxq2Eor.jpg',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#produkty',
          children: [
            {
              children: (
                <>
                  <p>Produkty</p>
                </>
              ),
              name: 'text',
              className: 'k3cyc6qjnmt-editor_css',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#o_nas',
          children: [
            {
              children: (
                <>
                  <p>
                    O Nas<br />
                  </p>
                </>
              ),
              name: 'text',
            },
          ],
          className: 'k3cycc54pkf-editor_css',
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#contact',
          children: [
            {
              children: (
                <>
                  <p>Kontakt</p>
                </>
              ),
              name: 'text',
              className: 'k3cycggddib-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        id: 'o_nas',
        children: (
          <>
            <p>Uczciwość, doświadczenie, pasja.</p>
          </>
        ),
        className: 'banner5-title k3cx38twvr6-editor_css',
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <>
            <p>
              Candela Lab jest firmą która posiada bogate doświadczenie w
              dostawie, serwisowaniu i szkoleniu w zakresie systemów aparatury
              analitycznej w tym chromatografii oraz bramek dozymetrycznych.
            </p>
          </>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <>
              <a href="mailto:pmusz@candelab.pl">Potrzebujesz wiecej informacji?</a>
            </>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        id: 'produkty',
        children: (
          <>
            <p>W czym możemy Ci pomóc?</p>
          </>
        ),
        className: 'k3cxovffvw-editor_css',
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>Sprzedaż</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    W ofercie firmy znajdziecie Państwo aparaturę oraz duży
                    wybór akcesoriów i części zamiennych nowych i
                    poleasingowych.
                  </p>
                </>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>Serwis</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    Dzięki wieloletniemu doświadczeniu świadczymy profesjonalne
                    usługi serwisowe w tym chromatografii oraz bramek
                    dozometrycznych.
                  </p>
                </>
              ),
              className: 'k3cxqsrhfj-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <>
                  <p>Szkolenia</p>
                </>
              ),
            },
            {
              name: 'content',
              children: (
                <>
                  <p>
                    Doświadczenie zdobyte w Stanach Zjednoczonych, Australii,
                    Kanadzie i Polsce a także ciągłemu podnoszeniu kwalifikacji,
                    pozwala nam świadczyć dla Państwa profesjonalne doradztwo i
                    szkolenia.
                  </p>
                </>
              ),
              className: 'k3cxqm47m9g-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <>
            <p>Czym się zajmujemy:</p>
          </>
        ),
        className: 'title-h1 k3cxshqhuxr-editor_css',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: {
            children: (
              <>
                <p>Chromatografia gazowa</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: {
            children: (
              <>
                <p>Chromatografia cieczowa</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: {
            children: (
              <>
                <p>GCMS</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: {
            children: (
              <>
                <p>LCMS</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: {
            children: (
              <>
                <p>Generatory H2, N2, R</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: {
            children: (
              <>
                <p>
                  Systemy mikrofalowe, absorbcja atomowa <br />
                </p>
              </>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
          },
          content: {
            children: (
              <>
                <p>Spektrofotometria</p>
              </>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
          },
          content: {
            children: (
              <>
                <p>Bramki Dozymetryczne</p>
              </>
            ),
          },
        },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper', id: 'contact',  },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: 'https://i.imgur.com/mxq2Eor.jpg' },
  textWrapper: { className: 'content2-text',  md: 14, xs: 24 },

  title: {
    className: 'content2-title k3cy6hxvsnh-editor_css',

    children: (
      <>
        <p>Skontaktuj się z Nami</p>
      </>
    ),
  },
  content: {
    className: 'content2-content',

    children: (
      <>
        <h3>
          <b>Candela Lab Paweł Musz</b>
        </h3>
        <h3>
          <p>Adres: Aleja Jana Pawła II 27, 00-867 Warszawa</p>
        </h3>
        <h3>
          <p>Tel: +48 22 354 68 09   Fax: +48 22 354 68 09</p>
        </h3>
        <h3>
          <p>Email: pmusz@candelab.pl</p>
        </h3>
        <div>
          <p>
            <br />
          </p>
        </div>
        <div>
          <br />
        </div>
        <div>
          <p>
            <br />
          </p>
        </div>
      </>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper k3cy4cepsif-editor_css',
  },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright k3cy2jwq53-editor_css',
    children: (
      <>
        <span>©2020 Candela Lab All Rights Reserved</span>
      </>
    ),
  },
};
